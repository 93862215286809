<template>
    <div class="logo">
        <div class="image" v-if="logo">
            <img src="@/img/logo-black.png"/>
        </div>
        <div class="name">
            <div class="top" v-if="!logo">
                Scala
            </div>
            <div class="bottom">
                Pick & Play
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "sc-logo",

  props: {
    logo: {
      default: false,
    }
  }
}
</script>

<style scoped lang="scss">

.logo {
    //text-align: center;

    img {
        height: 0.6em;
        margin-bottom: 0.3em;
    }

    div {
        line-height: 1em;
        font-size: 1em;
    }

    .name {
        font-weight: 700;
        color: #222;
        letter-spacing: 0.02em;

        .top {
            font-size: 0.7em;
            margin-bottom: 0.3em;
            font-weight: 700;
        }

        .bottom {
            font-size: 1.1em;
            text-transform: uppercase;
        }
    }
}


</style>
