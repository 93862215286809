<template>
    <div class="page">
        <bf-header/>
        <div class="start-screen">
            <div class="widget">
                <qr-scanner-inline @scanned="scannedCode" :key="key" @noPermission="noPermission()"></qr-scanner-inline>
            </div>
        </div>
    </div>
</template>

<script>
import QrScannerInline from "@/components/qr-scanner-inline";
import BfHeader from "@/components/bf-header";
import axios from 'axios';

export default {
  name: "scan",
  components: {BfHeader, QrScannerInline},

  data() {
    return {
      key: 1,
    }
  },

  methods: {

    noPermission() {
      this.$router.push('/')
    },

    scannedCode(result) {

      let parameters = result.split('/#/?')
      if (!parameters[1]) {
        console.error('Parameters missing')
        this.key++;
      }
      let params = new URLSearchParams(parameters[1]);
      if (params.get('ci') && params.get('si') && params.get('lang')) {
        this.$router.push({
          path: '/select',
          query: {
            lang: params.get('lang'),
            ci: params.get('ci'),
            si: params.get('si')
          }
        })
      } else {
        console.error('Parameters missing')
        this.key++;
      }

    },

    startSession(code) {

      let payload = {
        'user-id': this.$store.state.user_id,
        'session-id': code['uuid'],
        'solution': code['solution']
      };
      axios.post(this.$store.getters.getServerUrl + 'api/start-session', payload, {timeout: 3000})
        .then(res => {
          console.log(res);
          this.$notify.success("You are now logged in");
          this.$router.push('/')
        })
        .catch(err => {
          this.$notify.error('Failed to start session');
          this.key++;
          console.error(err.code);
        })
    },
  }
}
</script>

<style scoped lang="scss">

.page {
    background-color: #0F1C2B;
    background-image: url('~@/img/triangles.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 130% auto;
}

.start-screen {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1em;
    font-size: 1.2em;
}

</style>